document.addEventListener('DOMContentLoaded', () => {
    const scrollButton = document.getElementById('btn-back-to-top');
    if (!scrollButton) return;

    window.addEventListener('scroll', () => {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            scrollButton.style.display = 'block';
        } else {
            scrollButton.style.display = 'none';
        }
    });

    scrollButton.addEventListener('click', () => {
        const backToTopElement = document.getElementById('backToTop');
        if (backToTopElement) {
            backToTopElement.scrollIntoView();
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    });
});

// after page fully loads console.log hello world
document.addEventListener('DOMContentLoaded', function (event) {
    let partytown = document.querySelectorAll('script[type="text/partytown"]');
    partytown.forEach(function (el) {
        let script = document.createElement('script');
        script.src = el.getAttribute('data-src');
        document.body.appendChild(script);
        console.log('loaded', script.src)

        // remove the script tag
        el.remove();
    });
});
